import React from 'react'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout/checkout'
import { OrderSummary, PaymentPane } from '/src/components/commerce'
import { AsyncWrapper } from '/src/components/common'
import { useCart, useUser } from '/src/hooks'

const PaymentPageAsyncWrapper = () => {
  const cart = useCart()
  const user = useUser()

  if (!user) {
    return <></>
  }

  return (
    <Row className="mt-5">
      <Col lg={8}>
        <div className="title-container mb-4">
          <h1 className="title">Payment</h1>
        </div>
        <PaymentPane cart={cart} user={user} />
        <div className="mt-5">
          <Link to="/checkout/shipping" className="button outline">
            Back
          </Link>
        </div>
      </Col>
      <Col lg={4}>
        {cart && (
          <OrderSummary cart={cart} />
        )}
      </Col>
    </Row>
  )
}

const PaymentPage = () => {
  return (
    <Layout>
      <AsyncWrapper>
        <PaymentPageAsyncWrapper />
      </AsyncWrapper>
    </Layout>
  )
}

export default PaymentPage

export const Head = () => <title>Checkout Payment | Orrcon Steel</title>
