import React, { useState, useRef } from 'react'
import { Link } from 'gatsby'
import { Row, Col, Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faBookAtlas, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { StaticImage } from 'gatsby-plugin-image'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { LiveAgent } from '/src/components/chat'

import HeaderSlogan from '../HeaderSlogan'
import { Logo } from '../../common'
import SocialList from '../../social/SocialList'
import { Progress } from '../../commerce/checkout/Progress'

const LayoutHeader = ({ elementRef }) => {
  const [lockHeader, setLockHeader] = useState(false)

  useScrollPosition(({ prevPos, currPos }) => {
    // Have we passed the element yet?
    if (currPos.y <= -54) {
     setLockHeader(true)
    }
    else {
      setLockHeader(false)
    }
  }, [], elementRef)

  const headerClasses = ['commerce']
  if (lockHeader) {
    headerClasses.push('header-locked')
  }

  return (
    <header className={headerClasses.join(' ')}>
      <div className="header-top">
        <Container fluid="xxl">
          <Row className="d-flex align-items-center">
            <Col xs={12} md={6} className="d-flex justify-content-start">
              <HeaderSlogan />
            </Col>
            <Col xs={12} md={6} className="d-flex justify-content-end">
              <div className="top-menu-container">
                <ul className="menu">
                  <li>
                    <a target="_blank" href="https://sectiontranslation.com.au">
                      <FontAwesomeIcon icon={faBookAtlas} />
                      Section Translation Guide
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="header commerce">
        <Container fluid="xxl">
          <Row className="header-row d-flex align-items-center justify-content-center">
            <Col md={3} className="header-left-col">
              <Link to="/">
                <Logo />
              </Link>
            </Col>
            <Col md={9} className="header-right-col">
              <Row className="">
                <Col xs={12} md={8} className="product-search-col">
                  <Progress />
                </Col>
                <Col md={4} className="phone-col d-flex align-items-center justify-content-end">
                  <h5 className="m-0">
                    <a className="phone-link" href="tel:1300677266">
                      <FontAwesomeIcon icon={faPhone} />1300 677 266
                    </a>
                  </h5>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <LiveAgent />
    </header>
  )
}

export default ({ children }) => {
  const elementRef = useRef()

  return (
    <>
      <div ref={elementRef} className="app checkout">
        <LayoutHeader elementRef={elementRef} />

        <main>
          <Container>
            <Row className="d-flex justify-content-center">
              <Col xs={12}>
                {children}
              </Col>
            </Row>
          </Container>
        </main>

        <footer>
          <Container fluid="xxl">
            <Row className="footer-top-row d-flex align-items-center">
              <Col md={5} lg={3} className="d-flex">
                <h3 className="m-0">
                  <a className="phone-link" href="tel:1300677266">
                    <FontAwesomeIcon icon={faPhone} />1300 677 266
                  </a>
                </h3>
              </Col>
              <Col md={7} lg={6} className="d-flex justify-content-center">
                <h3 className="m-0">
                  <a className="email-link" href="mailto:info@orrcon.com.au">
                    <FontAwesomeIcon icon={faEnvelope} />info@orrcon.com.au
                  </a>
                </h3>
              </Col>
              <Col md={12} lg={3} className="d-flex justify-content-end">
                <SocialList />
              </Col>
            </Row>
          </Container>
        </footer>

        <div className="footer-bottom">
          <Container fluid="xxl">
            <Row className="d-flex align-items-center">
              <Col sm={6}>
                <div className="footer-copyright">
                  <span>© Orrcon Steel · A Business of BlueScope</span>
                  <ul className="list">
                    <li>
                      <a target="_blank" href="https://orrcon-static-assets.s3.ap-southeast-2.amazonaws.com/s3fs-public/2023-10/Orrcon%20Distribution%20Sales%20Terms%20and%20Conditions.pdf">
                        Conditions of Sale
                      </a>
                    </li>
                    <li>
                      <Link to="/privacy-policy">
                        Terms
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">
                        Privacy
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col sm={6} className="d-flex justify-content-end">
                <ul className="list">
                  <li>
                    <StaticImage 
                      src="../../../images/paypal.svg" 
                      alt="Paypal"
                      width={72}
                      height={20}
                      placeholder="blurred"
                      layout="fixed" />
                  </li>
                  <li>
                    <StaticImage 
                      src="../../../images/mastercard.svg" 
                      alt="Mastercard"
                      width={54}
                      height={42}
                      placeholder="blurred"
                      layout="fixed" />
                  </li>
                  <li>
                    <StaticImage 
                      src="../../../images/visa.svg"
                      alt="Visa"
                      width={56}
                      height={20}
                      placeholder="blurred"
                      layout="fixed" />
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  )
}
